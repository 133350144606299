h1,
h2,
h3,
h4,
p,
div {
  text-align: center;
  font-family: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif";
}

h2,
h1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

h4 {
  padding: none;
  margin: 10px;
}

h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}

p {
  font-size: 15px;
}

.numPosition {
  display: inline-block;
}

.introText {
  font-size: 50px;
}

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 20px;
  }
  h2,
  em {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  h4 {
    font-size: 14px;
  }
  p {
    font-size: 13px;
  }
}
